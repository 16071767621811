.service-item {
    position: relative;
    background-color: #ffffff; /* Optional: Adds a light background */
    text-align: center;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    padding-top: 3rem; /* Ensure enough space for the icon */
}


@media (max-width: 768px) {
    .service-item {
        margin-top: 2rem; /* Adjust the value as needed */
    }
}

.icon-wrapper {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80px;
    height: 80px;
    background-color:var(--primary); /* Primary color for the icon background */
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}


.icon-wrapper i {
    font-size: 2rem; /* Adjust icon size */
    color: white;

}

.service-item:hover {
    transform: translateY(-10px);
    box-shadow: 15px 4px 15px rgba(0, 0, 0, 0.2);
}


.service-item:hover .icon-wrapper {
    transform: translate(-50%, -60%);

}

.service-item .p-4 {
    padding: 2rem 1rem 1rem; /* Adjust padding for content */
}

.service-item h5 {
    margin-top: 3rem; /* Add top margin to clear the icon */
    font-weight: 600;
}


/*Menu*/

/* Default filter visibility */
.btn-group .btn {
    width: 150px; /* Adjust the width as needed */
    height: 80px; /* Adjust the height as needed */
    text-align: left;
    padding: 10px; /* Adjust padding for better alignment */
    border-radius: 8px; /* Optional: for rounded corners */
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn-group .btn .fa {
    font-size: 24px; /* Adjust icon size */
}

.btn-group .btn .ps-3 {
    margin-left: 10px; /* Space between icon and text */
}

@media (max-width: 768px) {
    .btn-group .btn {
        width: 120px; /* Smaller width for smaller screens */
        height: 60px; /* Smaller height for smaller screens */
    }
}

@media (max-width: 576px) {
    .btn-group .btn {
        width: 100%; /* Full width on very small screens */
        height: auto; /* Adjust height based on content */
    }
}

/*Event*/
.description-container {
    height: 100px; /* Set your desired fixed height */
    overflow: hidden;
    position: relative;
}

.read-more {
    display: none;
    position: absolute;
    bottom: 0;
    right: 0;
    background: linear-gradient(to right, transparent, white);
    cursor: pointer;
}

.description-container.expanded {
    height: auto; /* Expand to show full content */
    overflow: visible;
}

.description-container.expanded .read-more {
    display: none;
}

.description-container.collapsed .read-more {
    display: block;
}

/*Gallery*/
/* Gallery.css */
/* Gallery.css */
.gallery-container {
    position: relative;
}

.gallery-grid {
    margin-top: 30px;
    margin-right: 10px;
    margin-left: 10px;
    margin-bottom: 140px;
}

.masonry-item {
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
}

.gallery-image {
    width: 100%;
    height: auto;
    display: block;
    transition: transform 0.3s;
}

.masonry-item:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.modal {
    position: relative;
    max-width: 90%;
    max-height: 90%;
    margin: auto;
    padding: 0;
    background: transparent;
    border: none;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
}

.close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    color: white;
    font-size: 24px;
    cursor: pointer;
}

.modal-image {
    max-width: 100%;
    max-height: 80vh;
    margin: auto;
    display: block;
}

/*nav bar*/
/* Remove background and border from the dropdown button */
.navbar .dropdown-toggle {
    background-color: transparent; /* Make background transparent */
    border: none; /* Remove any border */
    color: #fff; /* Ensure the text color is visible */
    padding: 0; /* Adjust padding if needed */
    box-shadow: none; /* Remove any box shadow */
}

/* Optional: Remove background on hover/focus */
.navbar .dropdown-toggle:hover,
.navbar .dropdown-toggle:focus {
    background-color: transparent; /* Keep background transparent on hover/focus */
    color: #ddd; /* Change text color slightly on hover */
}

/* Adjust dropdown menu to prevent background overlap */
.navbar .dropdown-menu {
    margin-top: 0; /* Align dropdown menu with the button */
}




